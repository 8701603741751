import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/controllers/auth'

import usuario from '@/store/controllers/usuario'
import cliente from '@/store/controllers/cliente'
import casa from '@/store/controllers/casa'
import acceso from '@/store/controllers/acceso'
import perfil from '@/store/controllers/perfil'
import img from '@/store/controllers/img'
import qr from '@/store/controllers/qr'
import activity from '@/store/controllers/activity'
import registro from '@/store/controllers/registro'
// import session from '@/store/controllers/session'
import device from '@/store/controllers/device'
import reporte from '@/store/controllers/reporte'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activityLastUpdate: null,
    registrosLastUpdate: null
  },
  mutations: {
    updateActivityLastUpdate (state) {
      state.activityLastUpdate = new Date()
    },
    updateRegistrosLastUpdate (state) {
      state.registrosLastUpdate = new Date()
    }
  },
  actions: {
  },
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    usuario: {
      namespaced: true,
      ...usuario
    },
    cliente: {
      namespaced: true,
      ...cliente
    },
    casa: {
      namespaced: true,
      ...casa
    },
    acceso: {
      namespaced: true,
      ...acceso
    },
    perfil: {
      namespaced: true,
      ...perfil
    },
    img: {
      namespaced: true,
      ...img
    },
    qr: {
      namespaced: true,
      ...qr
    },
    activity: {
      namespaced: true,
      ...activity
    },
    registro: {
      namespaced: true,
      ...registro
    },
    // session: {
    //   namespaced: true,
    //   ...session
    // },
    device: {
      namespaced: true,
      ...device
    },
    reporte: {
      namespaced: true,
      ...reporte
    }
  }
})
