import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from '@/store/controllers/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    beforeEnter: (to, from, next) => {
      if (auth.getters.isAuthenticated()) {
        next({name: 'Dashboard'})
      } else {
        next({name: 'Login'})
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (auth.getters.isAuthenticated()) {
        if (to.query.redirect) {
          window.location.href = to.query.redirect
        } else {
          next({ name: 'Dashboard' })
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
      console.log(to.query, from.query);
      try {
        await Vue.$http.post(process.env.VUE_APP_API + `/logout`, { email: Vue.prototype.$cookies.get("email") })
      } catch (e) {
        console.log(e.message);
      }

      try {
        await Vue.prototype.$auth.logout()
        Vue.prototype.$cookies.remove("email")
      } catch (e) { console.error(e.message) }

      store.commit('auth/changeAuthStatus')

      next({ name: 'Root', query: to.query, params: to.params })
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    redirect: {name: 'Usuarios'},
    children: [
      {
        path: 'usuarios',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Usuarios',
            component: () => import('@/views/usuarios/Usuarios.vue')
          },
          {
            path: ':usuario_id',
            name: 'Usuario',
            component: () => import('@/views/usuarios/Usuario.vue')
          }
        ]
      },
      {
        path: 'clientes',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Clientes',
            component: () => import('@/views/clientes/Clientes.vue')
          },
          {
            path: ':cliente_id',
            name: 'Cliente',
            component: () => import('@/views/clientes/Cliente.vue')
          }
        ]
      },
      {
        path: 'perfiles',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Perfiles',
            component: () => import('@/views/perfiles/Perfiles.vue')
          },
          {
            path: ':perfil_id',
            name: 'Perfil',
            component: () => import('@/views/perfiles/Perfil.vue')
          }
        ]
      },
      {
        path: 'qrs',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'QRs',
            component: () => import('@/views/qr/QRs.vue')
          },
        ]
      },
      {
        path: 'actividad',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Actividad',
            component: () => import('@/views/activity/Dashboard.vue')
          },
          {
            path: ':registro_id',
            name: 'Registro',
            component: () => import('@/views/activity/Registro.vue')
          },
        ]
      },
      {
        path: 'reportes',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Reports',
            component: () => import('@/views/reports/Reports.vue')
          }
        ]
      },
      {
        path: 'dispositivos',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Devices',
            component: () => import('@/views/dispositivos/Devices.vue')
          },
          {
            path: ':device_id',
            name: 'Device',
            component: () => import('@/views/dispositivos/Device.vue')
          }
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.getters.isAuthenticated()) {
      console.log('sending to logout, not auth');
      next({ name: 'Logout', query: { redirect: window.location.origin + to.path }, params: to.params })
    }
  }

  next()
})

export default router
