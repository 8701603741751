import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/theme.scss'

Vue.config.productionTip = false

import VueAxios from 'vue-axios'
import axios from 'axios';
 
Vue.use(VueAxios, axios)

import VueAuthenticate from 'vue-authenticate'
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_API,
  loginUrl: '/login',
  storageNamespace: 'Miura',
  tokenPrefix: 'miura',
  tokenPath: 'token',
})

import Request from '@/helpers/request'
Vue.use(Request)

import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
localize('es', es)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

import FeatherWrapper from 'vue-feather-icons-wrapper'
Vue.use(FeatherWrapper)

import Moment from 'moment-timezone'
Moment.locale('es')
Vue.prototype.$moment = Moment

import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  color: '#00A8C1',
  failedColor: 'red',
  height: '4px'
})

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.mixin({
  data () {
    return {
      paginationPage: 1,
      paginationLimit: 50,
      paginationHasNextPage: false,
      paginationTotal: 0,
      loadingData: false
    }
  }
})

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

import { EventBus } from '@/helpers/eventBus'

window.OneSignal = window.OneSignal || [];
OneSignal.push(function() {
  OneSignal.init({
    appId: process.env.VUE_APP_ONESIGNAL_APP,
    autoResubscribe: true,
    promptOptions: {
      slidedown: {
        enabled: true,
        autoPrompt: true,
        timeDelay: 5,
        pageViews: 1
      }
    }
  });

  OneSignal.on('notificationDisplay', function(event) {
    EventBus.$emit('notification', event);
  });
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
