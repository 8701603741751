//libraries
import Vue from 'vue'
import axios from 'axios'

let resource = 'qr'
let resource_name = 'qr'
let resource_name_plural = 'qrs'

let state = {

}

state[resource_name_plural] = []

//functions receive state
let getters = {

}

//functions receive context and params
let actions = {
  async all ({ commit }, params) {
    try {
      commit('setItems', [])
      let res = await Vue.request.get(`/api/v1/${resource}`, {params: params.params || {}})
      if(res.data.success) {
        commit('setItems', res.data.resource)
        if(params.callback)
          params.callback(res.data)
      } else {
        if(params.callback)
          params.callback({error: `Error obteniendo ${resource_name_plural}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error obteniendo ${resource_name_plural}.`})
      console.error(e)
    }
  },
  async get ({ commit }, params) {
    try {
      let res = await Vue.request.get(`/api/v1/${resource}/${params[resource + '_id']}`, {params: params.params || {}})
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error obteniendo ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error obteniendo ${resource_name}.`})
      console.error(e)
    }
  },
  async create ({ commit }, params) {
    try {
      let res = await Vue.request.post(`/api/v1/${resource}`, params)
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error creando ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error creando ${resource_name}.`})
      console.error(e)
    }
  },
  async update ({ commit }, params) {
    try {
      let res = await Vue.request.patch(`/api/v1/${resource}/${params[resource + '_id']}`, params)
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error actualizando ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error actualizando ${resource_name}.`})
      console.error(e)
    }
  },
  async destroy ({ commit }, params) {
    try {
      let res = await Vue.request.delete(`/api/v1/${resource}/${params[resource + '_id']}`, {data: params})
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error eliminando ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error eliminando ${resource_name}.`})
      console.error(e)
    }
  },
  async scan ({ commit }, params) {
    try {
      let res = await Vue.request.post(`/api/v1/${resource}/scan`, params)
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error leyendo validez de ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error leyendo validez de ${resource_name}.`})
      console.error(e)
    }
  },
}

//functions receive state
let mutations = {
  setItems (state, items) {
    state[resource_name_plural] = items
  },
  updateQRInList (state, item) {
    let index = state[resource_name_plural].findIndex(q => q.qr_id == item.qr_id)
    if (index >= 0) {
      state[resource_name_plural].splice(index, 1, item)
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
