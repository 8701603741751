//libraries
import Vue from 'vue'
import axios from 'axios'

let resource = 'activity'
let resource_name = 'actividad'
let resource_name_plural = 'actividades'

let state = {
  activities: []
}

//functions receive state
let getters = {

}

//functions receive context and params
let actions = {
  async all ({ commit }, params) {
    try {
      // commit('setItems', [])
      let res = await Vue.request.get(`/api/v1/${resource}`, {params: params.params || {}})
      if(res.data.success) {
        commit('setItems', res.data.resource)
        if(params.callback)
          params.callback(res.data)
      } else {
        if(params.callback)
          params.callback({error: `Error obteniendo ${resource_name_plural}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error obteniendo ${resource_name_plural}.`})
      console.error(e)
    }
  },
  async get ({ commit }, params) {
    try {
      let res = await Vue.request.get(`/api/v1/${resource}/${params[resource + '_id']}`, {params: params.params || {}})
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error obteniendo ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error obteniendo ${resource_name}.`})
      console.error(e)
    }
  },
}

//functions receive state
let mutations = {
  setItems (state, items) {
    state.activities = items
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
