//libraries
import Vue from 'vue'

const state = {
  isAuth: false,
  updating_permissions: false
}

//functions receive state
const getters = {
  isAuthenticated (state) {
    return Vue.prototype.$auth.isAuthenticated()
  },
  authToken (state) {
    return Vue.prototype.$auth.isAuthenticated() ? Vue.prototype.$auth.getToken() : null
  }
}

//functions receive context and params
const actions = {
  async login (context, params) {
    try {
      const response = await Vue.prototype.$auth.login(params.user)
      context.commit('changeAuthStatus')
      params.callback(response)
    } catch (e) {
      console.error(e)
      params.callback({ error: 'Usuario o contraseña no válidos.' })
    }
  },
  async logout (context, params) {
    try {
      await Vue.prototype.$auth.logout()
      console.log('logout!!!');
      console.log(Vue.prototype.$cookies.get("email"));
      await Vue.request.post(`/api/v1/logout`, { email: Vue.prototype.$cookies.get("email") })
      Vue.prototype.$cookies.remove("email")
    } catch (e) {
      console.error('Logout:', e)
    }

    context.commit('changeAuthStatus')
  },
}

//functions receive state
const mutations = {
  changeAuthStatus (state) {
    state.isAuth = Vue.prototype.$auth.isAuthenticated()
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
