//libraries
import Vue from 'vue'
import axios from 'axios'

let resource = 'img'
let resource_name = 'imagen'
let resource_name_plural = 'imágenes'

let state = {

}

state[resource_name_plural] = []

//functions receive state
let getters = {

}

//functions receive context and params
let actions = {
  async create ({ commit }, params) {
    try {
      let res = await Vue.request.post(`/api/v1/${resource}`, params)
      if(res.data.success && params.callback) {
        params.callback(res.data)
      } else if (params.callback) {
        params.callback({error: `Error subiendo ${resource_name}.`})
      }
    } catch (e) {
      if(params.callback)
        params.callback({error: `Ocurrió un error subiendo ${resource_name}.`})
      console.error(e)
    }
  },
}

//functions receive state
let mutations = {
  setItems (state, items) {
    state[resource_name_plural] = items
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
