<template lang="pug">
  #app 
    router-view
    vue-progress-bar
</template>

<script>
import { EventBus } from '@/helpers/eventBus'
import {Howl} from 'howler'

export default {
  data () {
    return {
      chat_sound: null
    }
  },
  created () {
    EventBus.$on('notification', event => {
      this.$bvToast.toast(event.content, {
        title: `Notificación`,
        variant: 'danger',
        solid: true,
        noAutoHide: true
      })

      if (!this.chat_sound) {
        this.chat_sound = new Howl({
          src: [require('@/assets/sounds/alarm.mp3')]
        })
      }

      this.chat_sound.play()
    })
  }
}
</script>