import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

instance.interceptors.request.use( async config => {
  Vue.prototype.$Progress.start()

  if (Vue.prototype.$auth.isAuthenticated()) {
    config.headers['Authorization'] = Vue.prototype.$auth.getToken()
  } else {
    delete config.headers['Authorization']
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  Vue.prototype.$Progress.finish()

  Vue.prototype.$auth.setToken(response)
  return response
}, error => {

  if (error instanceof axios.Cancel) {
    router.push({name: 'Root'})
  }

  Vue.prototype.$Progress.fail()

  if (error.response && error.response.status === 401 || error.response.status === 403) {
    router.push({name: 'Logout', query: {unauth: 1} })
  }

  return Promise.reject(error)
})

export default {
  install(Vue, options) {
    Vue.request = instance
    Vue.prototype.$request = instance
  }
}
